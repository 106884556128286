import React from "react";
import CustomLink from "../../components/CustomLink";

const StandardHeader = ({
  label,
  title,
  description,
  displayGitHubButton,
  displayInfoButton,
  displayContactSales,
  displayImages,
  hideDemoRequest,
}) => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-10 md:pt-40 md:pb-40">
          {/* Hero content */}
          <div className="relative md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
            {/* Content */}
            <div className="pt-0 sm:pt-20 md:col-span-7 lg:col-span-7  md:mb-0 text-center md:text-left">
              <div className="flex flex-col gap-3">
              {label && (
                <span className="uppercase font-manrope text-xs sm:text-base tracking-[1.2px] sm:tracking-[1.6px] font-medium text-purple-1500">
                  {label}
                </span>
              )}

              <h1 className="relative sm:leading-12 leading-10 w-[240px] sm:w-auto md:mx-0 mx-auto tracking-tighter_3 text-3.25xl sm:text-4.5xl mb-5 text-purple-1100 font-manrope font-semibold z-1">
                {title}
              </h1>
              </div>
              <p className="relative w-[288px] text-center mx-auto sm:w-[648px] md:text-left md:w-[751px] -tracking-[0.45px] text-base sm:text-lg text-gray-600 dark:text-gray-400">
                {description}
              </p>

              {!hideDemoRequest && (
                <form className="mt-8">
                  <div className="flex flex-col sm:flex-row max-w-sm mx-auto sm:max-w-md md:mx-0">
                    <CustomLink
                      to={"/try-it-free/"}
                      className="btn text-white bg-purple-500 hover:bg-purple-400 shrink-0"
                    >
                      {displayContactSales ? "Free Trial" : "Free Trial"}
                    </CustomLink>

                    {displayGitHubButton && (
                      <a
                        href="https://github.com/encord-team/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-8 btn text-black bg-gray-200 hover:bg-gray-100 shrink-0"
                      >
                        <svg
                          className="w-8 h-8 fill-current"
                          viewBox="0 0 32 32"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                        </svg>
                      </a>
                    )}

                    {displayInfoButton && (
                      <a
                        href="https://venturebeat.com/2022/06/01/encord-offers-tool-to-automatically-detect-errors-in-training-data/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-8 btn text-black bg-gray-200 hover:bg-gray-100 shrink-0"
                      >
                        Read press coverage
                      </a>
                    )}
                  </div>
                </form>
              )}

              {displayImages && (
                <div className="mt-12 lg:w-[972px] flex flex-wrap gap-4">
                  {displayImages.map((image) => (
                    <div className="md:mx-0 mx-auto">{image}</div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StandardHeader;
